import programImg from "../../assets/img/thumbnail2.jpeg";

const programs = [
	{
		id: 1,
		title: "Tech House Mix",
		description: "Details Soon",
		img: programImg,
	},
	{
		id: 2,
		title: "Chillout Lounge",
		description: "Details Soon",
		img: programImg,
	},
	{
		id: 3,
		title: "Underground Sound",
		description: "Details Soon",
		img: programImg,
	},
	{
		id: 4,
		title: "Guest DJ",
		description: "Details Soon",
		img: programImg,
	},
	{
		id: 5,
		title: "Guest DJ",
		description: "Details Soon",
		img: programImg,
	},
];

export default programs;
