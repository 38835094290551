import React from 'react';

import Logo from '../assets/img/klf_logo.svg';
import Thumbnail from '../assets/img/thumbnail2.jpeg';

function Header() {
	return (
		<header className='w-full md:h-[70vh] flex  md:items-center bg-white'>
			<div className='header-bg'></div>
			<div className='w-full flex flex-col-reverse md:flex-row items-start justify-between z-10'>
				<div className='flex w-full md:w-4/5 flex-col'>
					<div className='flex flex-col md:flex-row md:bg-theme-secondary items-center justify-between px-5 h-full'>
						<span className='w-20 md:w-56 bg-black p-5 relative top-[-60px] left-[-20px] md:top-[-74px] self-start justify-self-start'>
							<img src={Logo} alt='radio-klf' />
						</span>
						<div className='text-white flex flex-col-reverse md:flex-row items-center flex-grow w-full md:w-auto md:px-8 p-3 bg-theme-secondary'>
							<div className='audio-player' autoPlay>
								<audio controls autoPlay>
									<source src='https://centova.gptradio.co/_proxy/8000/stream' type='audio/mpeg' />
								</audio>
							</div>
							<p className='font-extrabold uppercase text-2xl pl-5 pb-5 md:pb-0'>Listen Live</p>
						</div>
						<span className='md:w-56 md:h-56 bg-gray-500'>
							<img src={Thumbnail} alt='album' />
						</span>
					</div>
					<div className='w-4/5 bg-theme-primary h-8 md:h-20 self-center'></div>
				</div>
				<div className='bg-theme-accent h-16 w-1/2 md:w-[10%] flex items-center justify-evenly self-end md:self-start'>
					<p className='text-lg font-semibold'>LIVE</p>
					<div className='w-5 h-5 bg-black rounded-full'></div>
				</div>
			</div>
		</header>
	);
}

export default Header;
