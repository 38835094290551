import {faApple, faFacebook, faGooglePlay, faSpotify, faTwitter, faWhatsapp} from "@fortawesome/free-brands-svg-icons";
import {faPhone} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import React from "react";

import Logo from "../assets/img/klf_logo.svg";

function Footer() {
	return (
		<footer className='flex flex-col md:flex-row bg-theme-secondary p-16 py-14 items-center justify-between'>
			<img src={Logo} alt='radio klf' className='w-48' />
			<div className='flex flex-col my-3 md:my-0'>
				<h2 className='text-3xl font-extrabold uppercase text-white'>Radio KLF</h2>
				<div>
					<div className='flex items-center'>
						<FontAwesomeIcon icon={faPhone} className='cursor-pointer pr-2' />
						<p>Request Line (310) 243-6467</p>
					</div>
					<div className='flex items-center'>
						<FontAwesomeIcon icon={faWhatsapp} className='cursor-pointer pr-2' />
						<p>Telegram https://t.me/+HX28zXRQSgExYTRh</p>
					</div>
				</div>
			</div>
			<div className='flex flex-col md:w-1/5 my-3 md:my-0'>
				<h2 className='text-3xl font-extrabold uppercase text-white'>Streams</h2>
				<div className='flex text-3xl justify-between items-center mt:2 md:mt-8'>
					<FontAwesomeIcon icon={faGooglePlay} className='hover:-translate-y-2 ease-in-out cursor-pointer' />
					<FontAwesomeIcon icon={faApple} className='hover:-translate-y-2 ease-in-out cursor-pointer' />
					<FontAwesomeIcon icon={faSpotify} className='hover:-translate-y-2 ease-in-out cursor-pointer' />
					
				</div>
			</div>
			<div className='flex flex-col my-3 md:my-0'>
				<h2 className='text-3xl font-extrabold uppercase text-white'>Socials</h2>
				<div className='flex text-3xl justify-between items-center mt:2 md:mt-8'>
					<FontAwesomeIcon icon={faFacebook} className='hover:-translate-y-2 ease-in-out cursor-pointer' />
					<FontAwesomeIcon icon={faTwitter} className='hover:-translate-y-2 ease-in-out cursor-pointer' />
				</div>
			</div>
			<div className='flex flex-col my-3 md:my-0'>
				<h2 className='text-3xl font-extrabold uppercase text-white'>Share</h2>
				<div className='flex text-3xl justify-between items-center mt:2 md:mt-8'>
					<FontAwesomeIcon icon={faFacebook} className='hover:-translate-y-2 ease-in-out cursor-pointer' />
					<FontAwesomeIcon icon={faTwitter} className='hover:-translate-y-2 ease-in-out cursor-pointer' />
				</div>
			</div>
		</footer>
	);
}

export default Footer;
