import React from "react";

import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faSpotify, faGooglePlay, faApple} from "@fortawesome/free-brands-svg-icons";

function Live() {
	return (
		<section className='mt-[-36px] relative z-20 bg-theme-primary flex flex-col md:flex-row md:p-14 md:px-24 text-theme-primary'>
			<div className='bg-white w-full md:w-1/2 p-4 md:p-14'>
				<h1 className='text-5xl font-black'>STATION CREATED BY AI</h1>
				<p className='my-12'>
				Get ready to be amazed by the world's first fully AI-produced radio station! With our state-of-the-art technology, our station is constantly creating and curating the latest and greatest pop and dance hits, as well as classic favorites from the 90s to today.

But that's not all - our AI technology also produces and hosts all of our programs, including the DJs! That's right, our virtual hosts are programmed to bring you the best music and entertainment, all while providing seamless and ad-free listening on your mobile device.
					<br /> <br />
					Download our app from the App Store or Google Play today and experience the future of radio! With our AI-created station, you can enjoy the ultimate dance party anytime, anywhere.
				</p>
				<div className='flex text-theme-secondary text-3xl w-1/2 justify-between items-center'>
					<FontAwesomeIcon icon={faGooglePlay} className='hover:-translate-y-2 ease-in-out cursor-pointer' />
					<FontAwesomeIcon icon={faApple} className='hover:-translate-y-2 ease-in-out cursor-pointer' />
					<FontAwesomeIcon icon={faSpotify} className='hover:-translate-y-2 ease-in-out cursor-pointer' />
					{/* <FontAwesomeIcon icon={faYoutube} className='hover:-translate-y-2 ease-in-out cursor-pointer' /> */}
				</div>
			</div>
			<div className='w-full md:w-1/2 p-4 mb-16 md:mb-auto md:px-14 text-theme-secondary '>
				<h2 className='text-white text-3xl font-black'>LIVE HOURS</h2>
				<div className='flex items-center mb-12'>
					<p>20:00 ET</p>
					<div className='w-32 h-[1px] bg-theme-secondary mx-5'>
						<div className='w-1/2 h-full bg-white'></div>
					</div>
					<p>23:59 ET</p>
				</div>
				<h1 className='text-5xl font-semibold'>Club Anthems</h1>
				<p className='my-12'>From 8pm to midnight, let us keep you moving with the latest and greatest club anthems</p>
				<h5 className='uppercase text-white font-semibold'>PRESENTED BY</h5>
				<p className='text-3xl font-black'>DJ JESUS</p>
			</div>
		</section>
	);
}

export default Live;
