import React from "react";

import TextBox from "./input/TextBox";
import Thumbnail from "../assets/img/thumbnail2.jpeg";
import Select from "./input/Select";

function Contact() {
	return (
		<section className='md:px-24  py-14 flex flex-col md:flex-row justify-between'>
			<div className='w-full md:w-[45%] border-dashed border-theme-secondary border-4 p-8 md:p-12'>
				<form className='flex flex-col text-white'>
					<h1 className='text-white text-5xl font-black uppercase mb-12'>SHOUTOUTS</h1>
					<TextBox placeholder='Name' />
					<TextBox placeholder='E-mail' />
					<Select options={["Programming", "Sales", "Technical"]} />
					<textarea placeholder='Your Message' className='bg-[#4B4B4B] mb-4 p-3 ' />
					<button type='submit' className='bg-theme-accent px-20 py-2 uppercase font-bold w-min self-end text-black'>
						submit
					</button>
				</form>
			</div>
			<div className='w-full md:w-1/2 p-12 text-white'>
				<h1 className='text-5xl font-black uppercase mb-12'>LIVE THIS WEEK</h1>
				<p className='text-theme-accent text-xl'>
					Join us at the venue x for a night of music and fun. Venue Name Here with address <br />
					<br /> <br />
					Or watch it live on our TikTok
				</p>
				<div className='flex flex-col md:flex-row mt-24'>
					<img src={Thumbnail} alt='thumbnail' className='w-32 h-32 object-contain' />
					<div className='md:px-4 uppercase flex flex-col items-start'>
						<p className='bg-theme-accent text-black px-6 mb-2'> WEEK 22 | 2023</p>
						<h1 className='text-4xl font-extrabold'>KLF BANGER</h1>
						<p>R3HAB, INNA, SASH!</p>
						<p>ROCK MY BODY</p>
					</div>
				</div>
			</div>
		</section>
	);
}

export default Contact;
