import React from "react";

import programs from "../utils/data/programs";

function Programs() {
	return (
		<section className='md:px-24 text-white'>
			<h1 className='text-center md:text-left text-5xl font-black'>Programs</h1>
			<div className='overflow-x-scroll flex my-12'>
				{programs.map((p) => (
					<div className='bg-theme-secondary flex min-w-[400px] md:min-w-[600px] py-10 mr-9' key={p.id}>
						<img className='w-2/5' src={p.img} alt={p.title} />
						<div className='flex flex-col px-8 justify-between'>
							<h2 className='text-3xl font-semibold text-theme-primary uppercase'>{p.title}</h2>
							<p className='py-6'>{p.description}</p>
						</div>
					</div>
				))}
			</div>
		</section>
	);
}

export default Programs;
