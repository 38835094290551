import React from "react";

function Select({title, options}) {
	return (
		<select className='select bg-[#4B4B4B] mb-4 p-3'>
			{options.map((op, idx) => (
				<option key={idx}>{op}</option>
			))}
		</select>
	);
}

export default Select;
